



















.create-user-as-admin-page {
  max-width: 800px;
  justify-self: center;
}
